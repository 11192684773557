<template>
  <div>
    <!-- <validation-observer ref="asd"> -->

    <b-form-group
      label-cols-md="4"
      :label="label"
      :description="desc"
    >
      
      <validation-provider
        #default="{ errors }"
        :name="label"
        :rules="rules"
      >
        <cleave
          v-model="value"
          class="form-control"
          :options="options.number"
          :placeholder="placeholder"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <!-- </validation-observer> -->
  </div>
</template>

<script>
import {BFormGroup} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import { integer } from 'vee-validate/dist/rules'
  export default {
    components: {
      BFormGroup,
      ValidationProvider,
      Cleave
    },
    props: {
      value: {
        type: Number,
        default: 0
      },
      label: {
        type: String,
        default: 'LabelName'
      },
      placeholder: {
        type: String,
        default: ''
      },
      desc: {
        type: String,
        default: ''
      },
      rules: {
        type: String,
        default: ''
      }
    },
    data(){
      return{
        options: {
          date: {
            date: true,
            delimiter: '-',
            datePattern: ['Y', 'm', 'd'],
          },
          number: {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
          },
        },
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>