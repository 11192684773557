<template>
  <div class="row">
    <div class="col">
      <b-card title="Rencana Perawatan">
        <validation-observer ref="rulesMaintenance">
          <b-form>
            <form-v-select
              ref="issue"
              v-model="issue"
              :dataurl="'/issue?filter[status]=open&length='"
              :item_text="'subject'"
              :selected="issue"
              label="Issue"
              placeholder="Select Issue Kendaraan"
              @input="changeIssue"
            />
            <form-input
              v-model="code"
              rules="required"
              label="Kode Perawatan"
              placeholder="Kode Perawatan"
            />
            <!-- <form-switch
              v-model="is_external"
              label="Perawatan Eksternal"
            /> -->
            <form-input
              v-model="name"
              rules="required"
              label="Nama Perawatan"
              placeholder="Nama Perawatan"
            />
            <!-- <form-cleave
              v-model="plan_km"
              rules="required"
              label="KM Perawatan"
            /> -->
            <!-- <form-input
              v-model="plan_km"
              rules="required"
              label="KM Perawatan"
              type="number"
              min="0"
            /> -->
            <b-form-group label-cols-md="4" label="KM Perawatan">
              <cleave
                v-model="plan_km"
                class="form-control"
                :raw="true"
                :options="options.number"
                placeholder="KM Perawatan"
                rules="required"
              />
            </b-form-group>
            <form-date
              v-model="plan_date"
              rules="required"
              label="Tanggal Rencana"
              placeholder="Tanggal Rencana"
            />
            <!-- <form-cleave
              v-model="plan_cost"
              rules="required"
              label="Biaya Perencanaan"
            /> -->
            <!-- <form-input
              v-model="plan_cost"
              rules="required"
              label="Biaya Perencanaan"
              type="number"
              min="0"
            /> -->
            <!-- <form-cleave
              v-model="plan_service_cost"
              rules="required"
              label="Biaya Layanan Pemasangan"
            /> -->
            <!-- <form-input
              v-model="plan_service_cost"
              rules="required"
              label="Biaya Layanan Pemasangan"
              type="number"
              min="0"
            /> -->
            <form-input
              v-model="description"
              rules="required"
              label="Deskripsi"
              placeholder="Deskripsi"
            />
            <form-v-select
              ref="vehicle"
              v-model="vehicle"
              :dataurl="'/vehicle'"
              :item_text="'number'"
              :selected="vehicle"
              label="Kendaraan"
              rules="required"
              placeholder="Select Kendaraan"
              @input="changeVehicle"
            />
            <form-v-select
              ref="workshop"
              v-model="workshop"
              :dataurl="'/workshop'"
              :selected="workshop"
              label="Tempat Perawatan"
              rules="required"
              placeholder="Select Tempat Perawatan"
            />
          </b-form>
        </validation-observer>
        <hr>
        <b-form-group
          label-cols-md="4"
          label=""
        >
          <b-button
            class="mr-1"
            size="sm"
            variant="warning"
            type="button"
            @click.prevent="$router.go(-1)"
          >
            <span>Batal</span>
          </b-button>
          <b-button
            class="mr-1"
            size="sm"
            variant="primary"
            type="button"
            @click.prevent="validationFormMaintenance"
          >
          
            <span v-if="loading">
              <b-spinner small />
              Loading...
            </span>
            <span v-else>Simpan</span>
          </b-button>
        </b-form-group>
      </b-card>
    </div>
    <!-- <div class="col-sm-7">
      <b-card title="Item">
      
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col cols="12">
                <b-form-group
                  label-cols-md="4"
                  label="Tipe Kegiatan"
                  label-for="activity_type"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Activity"
                    rules="required"
                  >
                    <b-form-select
                      v-model="activity_type"
                      :options="enumActivity"
                      value-field="key"
                      text-field="name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label-cols-md="4"
                  label="Harga Satuan"
                  label-for="cost"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Cost"
                    rules="required"
                  >
                    <b-form-input
                      v-model="cost"
                      :plaintext="false"
                      placeholder="Inputkan Cost"
                      type="number"
                      autocomplete="off"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label-cols-md="4"
                  label="QTY"
                  label-for="qty"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Qty"
                    rules="required"
                  >
                    <b-form-input
                      v-model="qty"
                      type="number"
                      :plaintext="false"
                      placeholder="Inputkan Qty"
                      autocomplete="off"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label-cols-md="4"
                  label="Kode Barang"
                  label-for="item"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Item"
                    rules="required"
                  >
                    <baseSelect
                      v-model="item"
                      :dataurl="'/maintenance-item'"
                      title="item"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button
                  class="mr-1"
                  variant="warning"
                  type="button"
                  @click.prevent="reset"
                >
                  <span>Reset</span>
                </b-button>
                <b-button
                  class="mr-1"
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
                >
                
                  <span>Tambah</span>
                </b-button>
              
              </b-col>
              <br>
            </b-row>
          </b-form>
        </validation-observer>
        <br>
        <b-table
          striped
          responsive
          :items="details"
          :fields="table_details"
        >
          <template #cell(_)="data">
            <span
              class="mr-1"
            >
              <b-link @click="confirmDelete(data)">
                <b-badge variant="danger">
                  <feather-icon icon="TrashIcon" />
                </b-badge>
              </b-link>
            </span>
          </template>
          <template
            v-for="(_, slot) of $scopedSlots"
            v-slot:[slot]="scope"
          >
            <slot
              :name="slot"
              v-bind="scope"
            />
          </template>
        </b-table>
      </b-card>
    </div> -->
  </div>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {BCard, BForm, BRow, BCol, BTable, BLink, BBadge, BFormGroup, BFormSelect, BFormInput, BButton, BSpinner} from 'bootstrap-vue'
import baseSelect from '@/views/base/BaseSelect.vue'
import FormInput from '@/views/base/form/FormInput.vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import FormSwitch from '@/views/base/form/FormSwitch.vue'
import FormCleave from '@/views/base/form/FormCleave.vue'
import FormDate from '@/views/base/form/FormDate.vue'
import moment from 'moment'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    baseform,
    BForm,
    BCard,
    BTable,
    BLink,
    BBadge, 
    BFormGroup,
    BFormSelect,
    BFormInput,
    baseSelect,
    BButton,
    BRow, 
    BCol,
    FormInput,
    FormVSelect,
    FormSwitch,
    FormCleave,
    FormDate,
    BSpinner,
    Cleave
  },
  data() {
    return {
      dataurl:"/maintenance",
      baseroute:"maintenance",
      title:"Rencana Perawatan",
      // fields : [
      //   { key: 'issue', label: 'Isu Kendaraan', type: 'select2', dataurl:'/issue?filter[status]=open', key_value:'subject'},
      //   { key: 'code', label: 'Kode Perawatan', type: 'input', rules:'required' },
      //   { key: 'is_external', label: 'Perawatan Eksternal', type: 'bool'},
      //   { key: 'name', label: 'Nama Perawatan', type: 'input', rules:'required' },
      //   { key: 'plan_km', label: 'KM Perawatan', type: 'number', rules:'required' },
      //   { key: 'plan_date', label: 'Tanggal Rencana', type: 'date', rules:'required' },
      //   { key: 'plan_cost', label: 'Biaya Perencanaan', type: 'number', rules:'required' },
      //   { key: 'plan_service_cost', label: 'Biaya Layanan Pemasangan', type: 'number', rules:'required' },
      //   { key: 'description', label: 'Deskripsi', type: 'input', rules:'required' },
      //   { key: 'vehicle', label: 'Kendaraan'  , type: 'select-ajax', rules:'required', dataurl:'/vehicle', title:'Kendaraan', dataFields: [{ key: 'number', label: 'Nopol' }] },
      //   { key: 'workshop', label: 'Tempat Perawatan', type: 'select2', rules:'required', dataurl:'/workshop', title:'Bengkel' },
      //   { key: 'details', type: 'details'}
      // ],
      table_details:[
        { key: 'activity', label: 'Activity'},
        { key: 'cost', label: 'Cost' },
        { key: 'qty', label: 'Qty' },
        { key: 'item', label: 'Item'},
        '_'
      ],
      issue:null,
      code:"",
      is_external:false,
      name:"",
      plan_km:"0",
      plan_date:moment().format("Y-M-D"),
      plan_cost:"0",
      plan_service_cost:"0",
      description:"",
      vehicle:null,
      workshop:null,
      details:[],
      enumActivity:[
        {key: 'periksa', name: 'Pemeriksaan'},
        {key: 'ganti', name: 'Penggantian'},
        {key: 'tambah', name: 'Penambahan'}
      ],
      activity_type:"",
      cost:"",
      qty:"",
      item:{},
      dataItem:{},
      loading:false,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
    }
  },
  beforeMount(){
    if(this.$route.params.id != null){
      this.$http.get(this.dataurl+'/'+this.$route.params.id).then(res=>{
        this.code = res.data.code
        this.name = res.data.name
        this.is_external = res.data.is_external
        this.plan_km = res.data.plan_km
        this.plan_date = res.data.plan_date
        // this.plan_cost = res.data.plan_cost
        // this.plan_service_cost = res.data.plan_service_cost
        this.description = res.data.description
        this.vehicle = res.data.vehicle
        this.workshop = res.data.workshop

        // for(var index in res.data.details){
        //   this.details.push(
        //     {
        //       activity_type : res.data.details[index].activity_type,
        //       activity: this.enumActivity.find(x => x.key = res.data.details[index].activity_type).name,
        //       cost: res.data.details[index].cost, 
        //       qty: res.data.details[index].qty, 
        //       item: res.data.details[index].item.name,
        //       item_id: res.data.details[index].item_id
        //     })
        // }
      })
    }
  },
  methods: {
    changeIssue(data){
      if(data){
        this.vehicle = data.vehicle
        this.name = data.subject
        this.description = data.description
        this.updateWorkshopList()
      }else{
        this.vehicle = null
        this.name = ""
        this.description = ""
      }
      this.workshop = null
    },
    changeVehicle(data){
      if (data) {
        this.updateWorkshopList()
      }
      this.workshop = null
    },
    updateWorkshopList(){
        // Update Workshop List
        this.$refs.workshop.dataurl = '/workshop'+(this.vehicle ? '?contact_id='+this.vehicle.contact_id : '')
        this.$refs.workshop.getData()
    },
    confirmDelete(data){
      this.details.splice(data.index, 1)
    },
    reset(){
      this.cost = ""
      this.qty = ""
      this.item = ""
    },
    validationForm() {
      // this.$refs.simpleRules.validate().then( res => {
      //   if(res){
      //     this.details.push(
      //       {
      //         activity_type : this.activity_type,
      //         activity: this.enumActivity.find(x => x.key == this.activity_type).name,
      //         cost: this.cost, 
      //         qty: this.qty, 
      //         item: this.item.name,
      //         item_id: this.item.id
      //       })
      //       this.reset()
      //       this.$refs.simpleRules.reset()
      //   }else{
      //     this.$bvToast.toast('Please correct form input before submit', {
      //       title: 'Form Error',
      //       solid: true,
      //       variant:'danger'
      //     })
      //   }
      // })
    },
    validationFormMaintenance() {
      this.$refs.rulesMaintenance.validate().then( res => {
        if(res){
          // if(this.details.length == 0) 
          //   return this.$bvToast.toast('Please correct detail item before submit', {
          //     title: 'Form Error',
          //     solid: true,
          //     variant:'danger'
          //   })

          this.loading = true
          let params = {
            issue_id : this.issue?this.issue.id:null,
            code:this.code,
            is_external:this.is_external,
            name:this.name,
            plan_km:this.plan_km,
            plan_date:this.plan_date,
            // plan_cost:this.plan_cost,
            // plan_service_cost:this.plan_service_cost,
            description:this.description,
            vehicle_id:this.vehicle.id,
            workshop_id:this.workshop.id,
            details:this.details,
          }

          var html = null
          if(this.$route.params.id){
            this.dataurl = this.dataurl+"/"+this.$route.params.id
            html = this.$http.put(this.dataurl, params)
          }else{
            html = this.$http.post(this.dataurl, params)
          }
          
          html.then(()=>{
            this.successSubmit()
          })
          .catch(err=>{
            this.errorSubmit(err)
          })
          .finally(() => {this.loading = false})
        }else{
          this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    },
    successSubmit(){
      this.loading = false
      this.$bvToast.toast('', {
        title: 'Submit Success',
        solid: true,
        variant:'success'
      })
      this.$router.go(-1)
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
  }
}
</script>